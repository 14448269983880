<template>
  <sign-page
    title-text="发文签收"
    :request="request"
    :column-list="columnList"
    table-size="small"
    ref="xfqsPage"
    :table-actions="tableActions"
    @tableAction="tableAction"
    :getFileDataIds="getFileDataIds"
    :title-menus="[]"
    :table-actions-fixed="true"
    :need-data-file="true">
    <fm-modal title="安排人员" v-model="modalXf" :mask-closable="false" width="600px" v-if="modalXf">
      <org-user-choose
        :user-list="userList"
        :org-list="myOrgList"
        :his-data="handleList"
        style="max-height: 50vh;"
        ref="ouc"
      />
      <div class="btns">
        <fm-btn style="margin-right: 20px;" @click="signin">下发</fm-btn>
        <fm-btn @click="modalXf = false">取消</fm-btn>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  officialDocumentSendRequest as request,
} from '../../api'

import OrgUserChoose from './apply/orgUserChoose'


import TextCell from '@/components/base/TextCell'

import {
  userRequest
} from '@/api'

export default {
  components: {
    OrgUserChoose
  },
  computed: {
    myOrgList () {
      return this.$store.getters.orgList.filter(v => v.data.id === this.$store.getters.currentOrgId)
    },
    tableActions () {
      return [{
        key: 'data_file',
        label: '文件'
      },
      {
        key: 'assign',
        label: '安排人员',
        show (data) {
          return data.signinStatus === 'wait'
        }
      },
      {
        key: 'signin',
        label: '签收',
        show (data) {
          return data.signinStatus === 'wait' && data.status !== 'over'
        }
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '签收状态',
          field: 'statusText',
          sort: true
        },
        {
          title: '签收机构',
          field: 'orgName',
          sort: true
        },
        {
          title: '签收人',
          field: 'userName',
          sort: true
        },
        {
          title: '类型',
          field: 'officialDocumentType',
          sort: true
        },
        {
          title: '文件名称',
          field: 'officialDocumentTitle',
          sort: true,
          render: (h, row) => {
            return h(TextCell, {
              props: {
                value: row.officialDocumentTitle
              }
            })
          }
        },
        {
          title: '备注',
          field: 'remark',
          sort: true,
          render: (h, row) => {
            return h(TextCell, {
              props: {
                value: row.remark
              }
            })
          }
        },
        {
          title: '发文号',
          field: 'officialDocumentSendCode',
          sort: true
        },
        {
          title: '发文机构',
          field: 'officialDocumentSendOrgName',
          sort: true
        },
        {
          title: '发文份数',
          field: 'officialDocumentSendNum',
          sort: true
        },
        {
          title: '发文日期',
          field: 'officialDocumentSendTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.officialDocumentSendTime ? rowData.officialDocumentSendTime.slice(0, 10) : '-')
          }
        },
        {
          title: '归入案卷号',
          field: 'officialDocumentGetCode',
          sort: true
        },
        {
          title: '签收人',
          field: 'handleUserName',
          sort: true
        },
        {
          title: '签收时间',
          field: 'handleTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.handleTime ? rowData.handleTime.slice(0, 10) : '-')
          }
        }]
        return data
      }
    }
  },
  created () {
    this.$store.dispatch('loadOrgList')
    this.loadUser()
  },
  methods: {
    getFileDataIds (data) {
      return data.overFileIds ? data.overFileIds.split(',') : []
    },
    async tableAction (parm, vm) {
      this.chooseData = parm.data
      if (parm.action === 'signin') {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定签收该文件吗?'})
        if (result) {
          await request.handle(this.chooseData.officialDocumentSendId)
          vm.loadData()
        }
      } else if (parm.action === 'assign') {
        let data = await request.get({
          id: this.chooseData.officialDocumentSendId
        })
        this.handleList = data[0].handleList
        this.handleList.forEach(v => v.disabled = v.assignType === 'manage')
        this.modalXf = true
      }
    },
    async signin () {
      let datas = this.$refs.ouc.getChooseData()
      datas = datas.filter(v => v.type === 'user').map(v => v.data)
      await request.orgAssign(this.chooseData.officialDocumentSendId, {
        orgUserList: datas.map(v => {
          return {
            orgId: v.orgId,
            userId: v.id
          }
        })
      })
      this.$notify({
        title: '系统提示',
        message: '完成下发。',
        type: 'success'
      })
      this.modalXf = false
      this.$refs.xfqsPage.loadData()
    },
    async loadUser () {
      this.userList = await userRequest.get({needRoleInfo: 1})
    },
    async loadData () {
      let data = await request.getMyHandle({
        officialDocumentSendStatus: 'end'
      })
      let statusMap = {
        'wait': '待签收',
        'over': '已签收'
      }
      data.forEach(v => {
        v.statusText = statusMap[v.status]
      })
      return data
    }
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      handleList: [],
      userList: [],
      signinOrgIds: [],
      statusMap: {},
      modalXf: false,
      statusConfig: {},
      modal: false,
      chooseData: null
    }
  }
}
</script>

<style scoped lang="less">
.btns {
  justify-content: center;
  display: flex;
  margin-top: 10px;
}
</style>